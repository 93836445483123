import { FC, memo } from 'react';
import { Translations } from '../../../models/Translation';
import LanguageUtils from '../../../utils/LanguageUtils';

type Prop = {
  id: string;
  translations: Translations;
};

const TemplateFormsRenderer: FC<{ data: Prop }> = (props) => {
  if (!props.data) {
    return <>-</>;
  }

  const { data } = props;

  return (
    <div className="max-h-16 overflow-y-auto overflow-x-clip">
      <div key={data.id}>{LanguageUtils.getTranslation('title', data.translations)}</div>
    </div>
  );
};

export default memo(TemplateFormsRenderer);
